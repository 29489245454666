window.addEventListener('DOMContentLoaded', () => {
  window.addEventListener('htmx:load', () => {
    const radioInputs = document.getElementsByName('import_type');

    const divBrandNew = document.getElementById('div_brand_new');
    const divBrandExisting = document.getElementById('div_brand_existing');

    const importTypeDivs = document.querySelectorAll('.import_type');

    function toggle(elm) {
      if (elm.checked && elm.value === 'brand_mixed') {
        importTypeDivs.forEach(div => {
          div.style.display = 'none';
        });
      } else if (elm.checked && elm.value === 'brand_new') {
        divBrandNew.style.display = 'block';
        divBrandExisting.style.display = 'none';
      } else if (elm.checked && elm.value === 'brand_existing') {
        divBrandNew.style.display = 'none';
        divBrandExisting.style.display = 'block';
      }
    }

    radioInputs.forEach(radioInput => {
      toggle(radioInput);
      radioInput.addEventListener('change', function() {
        toggle(this);
      });
    });
  });
});
